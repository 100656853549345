<template>
  <div class="page">
    <van-form>
      <van-cell-group title="修改登陆密码">
        <van-field
          label="原密码"
          prop="oldpassword"
          v-model="oldpassword"
          placeholder="请输入当前密码"
        >
        </van-field>
      </van-cell-group>
      <van-cell-group title="新密码">
        <van-field
          label="新密码"
          prop="password"
          v-model="password"
          placeholder="新密码"
        >
        </van-field>
        <van-field
          label="确认新密码"
          prop="repassword"
          v-model="repassword"
          placeholder="确认新密码"
        >
        </van-field>
      </van-cell-group>
      <van-cell>
        <van-button type="info" icon="success" block @click="submitForm"
          >更改登陆密码</van-button
        >
      </van-cell>
    </van-form>
  </div>
</template>

<script>
import Vue from "vue";
import { Form, Field } from "vant";
Vue.use(Form).use(Field);

export default {
  name: "changePassword",
  data() {
    return {
      oldpassword: "",
      password: "",
      repassword: "",
    };
  },
  methods: {
    submitForm() {
      const { oldpassword, password, repassword } = this;
      this.$http
        .post("user/change_password", { oldpassword, password, repassword })
        .then((res) => {
          const { code, msg } = res;
          this.$dialog.alert({ title: "提示", message: msg }).then(() => {
            if (code === 1) {
              doLogout().then(() => {
                this.$router.push({ name: "login" });
              });
            }
          });
        });
    },
  },
};
</script>
